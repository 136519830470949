import CommandSet from "./CommandSet"
import { commands } from "./Constants"
import "./Terminal.css"

const Terminal = () => {
    return <div className="term" style={{minHeight: "100vh", minWidth: "100%", color: "white", paddingBottom: "10px"}}>
        <CommandSet commands={commands}/>
    </div>
}

export default Terminal