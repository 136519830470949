import { useState } from 'react';
import Stylistic from './stylistic/Stylistic';
import Terminal from './terminal/Terminal';

function App() {
  const [terminalMode, setTerminalMode] = useState(false);

  return (
    <div style={{padding: 0}}>
      <div style={{position: "absolute", right: 0, display: "flex", flexDirection: "column", alignItems: "center", fontFamily: "sans-serif"}}>
        <button 
          style={{color: "white", backgroundColor: "black", fontFamily: "monospace", margin: "1vw"}}
          onClick={() => setTerminalMode(!terminalMode)}
        >
          Click to toggle<br/>terminal style
        </button>
      </div>
      {terminalMode ? <Terminal/> : <Stylistic/>}
    </div>
  );
}

export default App;
