import { aboutMeText, cvLink, cvName, devops, education, frameworksAndLibrareis, jobs, languages, miscellaneousSkills, Skill } from "../Constants"
import TerminalExperience from "./TerminalExperience"
import TerminalTable from "./TerminalTable"

export const typingTime = 2000

export const termName='nathan@website' 

export const termPath='~'

export const banner = String.raw` ________   ________  _________  ___  ___  ________  ________              ___  ________  ________   _______   ________      
|\   ___  \|\   __  \|\___   ___\\  \|\  \|\   __  \|\   ___  \           |\  \|\   __  \|\   ___  \|\  ___ \ |\   ____\     
\ \  \\ \  \ \  \|\  \|___ \  \_\ \  \\\  \ \  \|\  \ \  \\ \  \          \ \  \ \  \|\  \ \  \\ \  \ \   __/|\ \  \___|_    
 \ \  \\ \  \ \   __  \   \ \  \ \ \   __  \ \   __  \ \  \\ \  \       __ \ \  \ \  \\\  \ \  \\ \  \ \  \_|/_\ \_____  \   
  \ \  \\ \  \ \  \ \  \   \ \  \ \ \  \ \  \ \  \ \  \ \  \\ \  \     |\  \\_\  \ \  \\\  \ \  \\ \  \ \  \_|\ \|____|\  \  
   \ \__\\ \__\ \__\ \__\   \ \__\ \ \__\ \__\ \__\ \__\ \__\\ \__\    \ \________\ \_______\ \__\\ \__\ \_______\____\_\  \ 
    \|__| \|__|\|__|\|__|    \|__|  \|__|\|__|\|__|\|__|\|__| \|__|     \|________|\|_______|\|__| \|__|\|_______|\_________\
                                                                                                                 \|_________|
                                                                                                                             
                                                                                                                             `

function skillsToRow(skills: Skill[]) {
    return skills.map(skill => skill.name).join(", ")
}

const skills = <>
    <TerminalTable 
        header={[]} 
        rows={[
            ["Languages", skillsToRow(languages)],
            ["Frameworks And Librareis", skillsToRow(frameworksAndLibrareis)],
            ["DevOps", skillsToRow(devops)],
            ["Miscellaneous", skillsToRow(miscellaneousSkills)]
        ]}
    />
</>

export const commands = [
    {command: "cat banner.txt", children: <pre>{banner}</pre>},
    {command: "find . -name Nathan_Jones_CV.pdf", children: <p><a href={cvLink} >{"./"+cvName}</a></p>},
    {command: "cat aboutMe.txt", children: <p>{aboutMeText}</p>},
    {command: "cat experience.txt", children: <TerminalExperience jobs={jobs}/>},
    {command: "cat skills.txt", children: skills},
    {command: "cat education.txt", children: <TerminalTable {...education}/>}
]