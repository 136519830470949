import React, { useEffect, useState } from "react"
import Command, { CommandProps } from "./Command"

interface CommandSetProps {
    commands: CommandProps[]
}

const CommandSet = ({commands}: CommandSetProps) => {
    const [visibleCommands, setVisibleCommands] = useState<React.ReactNode[]>([])
    const [currentCommandIndex, setCurrentCommandIndex] = useState(0)

    useEffect(() => {
        if (currentCommandIndex < commands.length) {
            const next = commands[currentCommandIndex]
            const nextCommand = <Command key={currentCommandIndex}  {...next} onFinish={() => {
                if(next.onFinish) next.onFinish(); 
                setCurrentCommandIndex(currentCommandIndex+1)
            } 
            }/>
            setVisibleCommands(commands => [...commands, nextCommand])
        }
    }, [currentCommandIndex, commands, setVisibleCommands, setCurrentCommandIndex])
    
    return(
        <div style={{fontFamily: "Courier New"}}>
            {visibleCommands}
        </div>
    )
}

export default CommandSet