import { useEffect, useState } from "react"
import { termName, termPath, typingTime } from "./Constants"
import TypingText from "./TypingText"

export interface CommandProps {
    command: string,
    children: React.ReactNode,
    onFinish?: () => void
}

const Command = ({command, children, onFinish=()=>{}}: CommandProps) => {
    const [isTextTyped, setIsTextTyped] = useState(false)

    useEffect(() => {
        if(isTextTyped) onFinish()
    }, [isTextTyped, onFinish])
    
    return <div>
        <span id="termName">{termName}</span>
        <span>:</span>
        <span id="termPath">{termPath}</span>
        <span>$ </span>
        <span><TypingText text={command} time={typingTime} onFinish={() => setIsTextTyped(true)}/></span>
        {isTextTyped ? children : null}
    </div>
}

export default Command