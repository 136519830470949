import { Job } from "../Constants"

const TerminalExperience = ({jobs}: {jobs: Job[]}) => {
    return <div style={{display: "flex", flexDirection: "column", gap: "10px", whiteSpace: "pre-wrap", overflow: "visible", fontFamily: "Courier New"}}>
        {jobs.map((job) => <div>
            <span>{job.title}</span>
            <span style={{color: "#CCCCCC"}}> at </span>
            <a href={job.companyHomepage}>{job.companyName}</a>
            <span> ({job.startDate} - {job.endDate})</span>
            <div style={{paddingLeft: "2vw"}}>
                <ul>
                    {job.description.map(row => <li>{row}</li>)}
                </ul>
            </div>
            </div>)}
    </div>
}

export default TerminalExperience;