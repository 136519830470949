export const aboutMeText = `Hello 👋!

My name is Nathan and I am a third-year computer science student at The University of Manchester.

Over the last year, I have been on placement working for an industry-leading 'cloud-native data integration and transformation' software provider called Matillion. My time at Matillion has fuelled my passion for building new and impactful software that will make a real difference to its end users. I enjoy knowing that my work will eventually make someone else's day easier or allow them to achieve something that previously was not possible.

At university, on placement, and in my own time, I love learning about computing, from processor microarchitecture to AI. However, my favourite topic is the study of how complex distributed systems are built and architected. On placement, I have been fascinated by Matillion's hybrid SaaS architecture and have enjoyed designing a new system with a microservice architecture. I have spent time teaching myself about orchestration tools such as Kubernetes, and next year I am excited to undertake my final year project investigating unikernels, which may be the future of how web services are deployed 🧐.`

export interface Job {
    title: string,
    companyName: string,
    companyHomepage: string,
    startDate: string,
    endDate: string,
    description: React.ReactNode[]
}

export const matillionJob: Job = {
    title: "Innovation Engineer",
    companyName: "Matillion",
    companyHomepage: "https://www.matillion.com/",
    startDate: "July 2021",
    endDate: "Present",
    description: [
    <>Worked as a placement student for 14 months and have been invited back to work part time in my third year of university.</>,
    <>Work in The Office of the CTO developing proofs of concept (POC) for new products and features.</>,
    <>Developed a large POC to test how Matillion's product offerings could work in a <b>SaaS</b> model. This POC was developed using a <b>micro-service architecture</b>.</>,
    <>Developed POCs for new products in the field of <b>Data-Ops</b> which where built on top of and took advantage of the SaaS/micro-service POC above.</>,
    <>Used <b>Java</b> and <b>Kotlin</b> with <b>Spring Boot</b> to develop <b>RESTful</b> back-end API services.</>,
    <>Used <b>React</b> and <b>TypeScript</b> to build front-ends for the above back-end services.</>,
    <>Deployed POCs on <b>AWS</b> in a <b>highly available</b>, <b>auto-scaling</b> configuration using: <b>EC2</b>, <b>ECS</b>, <b>CloudFront</b>, <b>S3</b>, <b>RDS</b>, <b>DynamoDB</b>, <b>Route 53</b>, <b>ELB</b>, (and more)</>,
    <>Deployed <b>CI/CD</b> solutions using <b>Bitbucket Pipelines</b>, <b>Circle CI</b> and <b>Terraform</b> to aid in fast paced POC development.</>
]
}

const passJob: Job = {
    title: "PASS Leader",
    companyName: "The University of Manchester",
    companyHomepage: "http://www.peersupport.manchester.ac.uk/what-is-peer-support/what-is-pass/",
    startDate: "September 2020",
    endDate: "July 2021",
    description: [
    <>Lead and mentored 14 first year students in their studies.</>,
    <>Created an inclusive and collaborative community between students and facilitated learning between peers.</>,
    <>Employed planning and time management skills to make the sessions as useful as possible.</>
]
}

const mmrgJob: Job = {
    title: "Data Analyst/Researcher",
    companyName: "MMRG",
    companyHomepage: "https://mmr-g.com/",
    startDate: "August 2020",
    endDate: "September 2020",
    description: [
    <>Developed tools in <b>Python</b> to automate data analytics and made use of open web APIs to automate data gathering.</>,
    <>Performed manual data analytics to contribute towards deliverables.</>
]
}

const rowingCoaching: Job = {
    title: "Rowing Coach",
    companyName: "Guildford Rowing Club",
    companyHomepage: "https://guildfordrowingclub.org.uk/",
    startDate: "October 2017",
    endDate: "July 2019",
    description: [
    <>Coached the Junior Squad every weekend.</>,
    <>Employed communication skills to ensure that the children understood my instructions and advice.</>,
    <>Acted responsibly by ensuring the children were safe at all times while rowing on the water and training on land.</>
]
}

export const jobs: Job[] = [matillionJob, passJob, mmrgJob, rowingCoaching]

export interface Skill {
    name: string
    logo: string
}

export const languages = [
    {name: "Java", logo: "java-icon.svg"}, 
    {name: "Kotlin", logo: "Kotlin-logo.svg"},
    {name: "TypeScript", logo: "ts-logo-512.svg"},
    {name: "JavaScript", logo: "js-logo.svg"},
    {name: "Python", logo: "python-logo.svg"},
    {name: "Bash", logo: "bash-logo.svg"},
]

export const frameworksAndLibrareis = [
    {name: "Spring Boot", logo: "java-icon.svg"},
    {name: "React", logo: "react-logo.svg"},
]

export const devops = [
    {name: "Maven", logo: "java-icon.svg"},
    {name: "Terraform", logo: "terraform-logo.svg"},
    {name: "AWS (EC2, ECS, S3, DynamoDB, CloudFront, Route 53, IAM, ...)", logo: "aws-logo.svg"},
    {name: "Containerisation (Docker)", logo: "docker-logo.svg"},
    {name: "Docker Compose", logo: "docker-logo.svg"},
    {name: "Bitbucket Pipelines", logo: "ci-cd.svg"},
    {name: "Kubernetes", logo: "k8s-logo.svg"}
]

export const miscellaneousSkills = [
    {name: "Git", logo: "git-logo.svg"},
    {name: "Linux", logo: "linux-logo.svg"}
]

export const cvName = "Nathan_Jones_CV.pdf"
export const cvLink = "/"+cvName

export interface TableContents {
    header: string[]
    rows: string[][]
}

export const education = {
    header: ["Subject/Level", "Grade"],
    rows: [
        ["BSc Computer Science with Industrial Experience", ""],
        ["  - Year 1", "91.349%"],
        ["  - Year 2", "90.417%"],
        ["  - Awarded Kilburn Entry Scholarship", "Awarded to top ~25% of students in year"],
        ["A-Levels", ""],
        ["  - Further Mathematics", "A*"],
        ["  - Mathematics", "A*"],
        ["  - Physics", "A*"],
        ["AS-Levels", ""],
        ["  - Chemistry", "A"],
        ["GCSEs", ""],
        ["  - Further Mathematics", "A* with Distinction"],
        ["  - Mathematics", "9"],
        ["  - English Language", "7"],
        ["  - English Literature", "7"],
        ["  - 6 other GCSEs", "A*-A"],
    ]
}