import { aboutMeText, cvLink, devops, education, frameworksAndLibrareis, jobs, languages, miscellaneousSkills } from "../Constants"
import Divider from "./Divider"
import Experience from "./Experience"
import Section from "./Section"
import StylisticSkills from "./Skills"
import "./Stylistic.css"
import StylisticTable from "./Table"

const Stylistic = () => {
    return <div className={"stylistic"}>
        <a href="https://www.linkedin.com/in/nathan-ec-jones" style={{position: "absolute", top: "2vh", left: "2vw"}}>
            <img src="LinkedIn_Logo.svg" alt="LinkedIn Profile Link" height="40" width="147" />
        </a>
        <Section theme="primary" divStyle={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} fullScreen={true}>
            <h1 style={{marginBottom: "1vh"}}>Nathan Jones</h1>
            <h3>Software Engineer · Computer Scientist</h3>
            <a href={cvLink}>Quick Link to CV</a>
            <h1>↓</h1>
        </Section>
        <Divider theme="primaryToSecondary"/>
        <Section theme="secondary">
            <h1>About Me</h1>
            <p>{aboutMeText}</p>
        </Section>
        <Divider theme="secondaryToPrimary"/>
        <Section theme="primary">
            <h1>Experience</h1>
            <Experience jobs={jobs}/>
        </Section>
        <Divider theme="primaryToSecondary"/>
        <Section theme="secondary">
            <h1>Education</h1>
            <StylisticTable {...education}/>
        </Section>
        <Divider theme="secondaryToPrimary"/>
        <Section theme="primary" divStyle={{display: "flex", flexDirection: "column", gap: "10vh"}}>
            <h1>Skills</h1>
            <h2>languages</h2>
            <StylisticSkills skills={languages}/>
            <h2>Frameworks/Libraries</h2>
            <StylisticSkills skills={frameworksAndLibrareis}/>
            <h2>DevOps</h2>
            <StylisticSkills skills={devops}/>
            <h2>Miscellaneous</h2>
            <StylisticSkills skills={miscellaneousSkills}/>
            <br/>
            <br/>
        </Section>
    </div>
}

export default Stylistic