interface AsciiTableProps {
    header: string[]
    rows: string[][]
}

function genHorizontalLine(colLenghts: number[]) {
    let horizontalLine = "";
    for (const colLength of colLenghts) {
        horizontalLine += "+"+"-".repeat(colLength)
    }
    horizontalLine += "+\n"
    return horizontalLine;
}

function genRow(row: string[], colLenghts: number[]) {
    let rowText = ""
    for (let colIndex = 0; colIndex < colLenghts.length; colIndex++) {
        rowText += "|" + (row[colIndex] ?? "").padEnd(colLenghts[colIndex])
    }
    rowText += "|\n"
    return rowText
}

const TerminalTable = ({header, rows}: AsciiTableProps) => {
    var colLenghts = [];
    for (const row of [...rows, header]) {
        for (let colIndex = 0; colIndex < row.length; colIndex++) {
            const colMaxLength = colLenghts[colIndex] ?? 0
            const colLength = row[colIndex].length
            if (colLength > colMaxLength) {
                colLenghts[colIndex] = colLength
            }
        }
    }

    let text = ""

    // generate header
    const horizontalLine = genHorizontalLine(colLenghts)
    text += horizontalLine;
    text += genRow(header, colLenghts);
    text += horizontalLine;
    for (const row of rows) {
        text += genRow(row, colLenghts);
    }
    text += horizontalLine
    return <p style={{whiteSpace: "pre", overflow: "visible"}}>
        {text}
    </p>
}

export default TerminalTable;