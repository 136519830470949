import { TableContents } from "../Constants"

const StylisticTable = ({header, rows}: TableContents) => {
    return <table>
        <tbody>
            <tr>{header.map(data => <th>{data}</th>)}</tr>
            {rows.map(row => <tr>{row.map(data => <td>{data}</td>)}</tr>)}
        </tbody>
    </table>
}

export default StylisticTable