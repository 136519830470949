import { darkBlue, lightBlue } from "./Consts"

interface SectionProps {
    theme: "primary" | "secondary"
    divStyle?: any
    fullScreen?: boolean
    children: React.ReactNode
}

const Section = ({theme: style, divStyle={}, fullScreen=false, children}: SectionProps) => {

    const backgroudColor = style === "primary" ? lightBlue : darkBlue
    const textColor = style === "primary" ? darkBlue : lightBlue

    return <div style={{minHeight: fullScreen ? "100vh" : 0, width: "96%", backgroundColor: backgroudColor, color: textColor, padding: "2%", ...divStyle}}>
        {children}
    </div>
}

export default Section