import { Skill } from "../Constants"
import 'react-circular-progressbar/dist/styles.css';

const StylisticSkills = ({skills}: {skills: Skill[]}) => {
    return <div style={{display: "flex", width: "100%", flexWrap: "wrap", gap: "10vw", justifyContent: "space-around"}}>
        {skills.map(skill => <div style={{width: "200px", textAlign: "center"}}>
            <img src={skill.logo} alt={"Skill Logo"} height="100" width="100" />
            <p>{skill.name}</p>
        </div>)}
    </div>
}

export default StylisticSkills