import { Job } from "../Constants"

const Experience = ({jobs}: {jobs: Job[]}) => {
    let renderedJobs = jobs.map((job) => {
        return <>
            <p>
                <b>{job.title}</b> at <a href={job.companyHomepage}>{job.companyName}</a>
                <span style={{float: "right"}}>({job.startDate} - {job.endDate})</span>
            </p>
            <ul>
                {job.description.map(point => <li>{point}</li>)}
            </ul>
        </>
    })

return <div style={{display: "flex", flexDirection: "column", gap: "1vh"}}>
        {renderedJobs}
    </div>
}

export default Experience