import { useEffect, useState } from "react"

interface TypingTextProps {
    text: string,
    time: number,
    curser?: boolean,
    onFinish?: () => void
}

const TypingText = ({text, time, curser=true, onFinish}: TypingTextProps) => {
    const [typedText, setTypedText] = useState("")
    const [curserCharacter, setCurserCharacter] = useState(curser ? "▊" : "")

    useEffect(() => {
        if (typedText !== text) {
            setTimeout(
                () => setTypedText(text.substring(0, typedText.length+1)),
                time/text.length
            )
        }
    }, [text, typedText, time])

    useEffect(() => {
        if (typedText === text) {
            setCurserCharacter("")
            if(onFinish) onFinish()
        }
    }, [typedText, text, setCurserCharacter, onFinish])

    return (<>{typedText}{curserCharacter}</>)
}

export default TypingText