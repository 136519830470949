import { darkBlue, lightBlue } from "./Consts"

interface DividerProps {
    theme: "primaryToSecondary" | "secondaryToPrimary"
}

const Divider = ({theme: style}: DividerProps) => {

    const topColor = style === "primaryToSecondary" ? lightBlue : darkBlue
    const bottomColor = style === "primaryToSecondary" ? darkBlue : lightBlue

    return <div style={{height: "10vh", width: "100%", backgroundColor: topColor}}>
        <div style={{height: "100%", width: "100%", backgroundColor: bottomColor, clipPath: "polygon(0% 100%, 100% 100%, 100% 0%)"}}></div>
    </div>
}

export default Divider